import { type Component } from 'solid-js';
import { TranslationContextProvider } from './i18n/TranslationProvider';
import Layout from './components/pages/layout';
import { Route, Router } from '@solidjs/router';
import LoginPage from './components/pages/login';
import HomePage from '@pages/home';
import { DependencyInjectionContextProvider } from './dependencyInjection/DependencyInjectionProvider';
import Routes from './Routes';
import VotingHistoryPage from '@pages/voting/history';
import RegisterPage from '@pages/register/registerPage';
import RoomPage from '@pages/room';

const App: Component = () => {
    return (
        <TranslationContextProvider>
            <DependencyInjectionContextProvider>
                <Router root={Layout}>
                    <Route path={Routes.Index} component={HomePage} />
                    <Route path={Routes.Login} component={LoginPage} />
                    <Route path={Routes.Register} component={RegisterPage} />
                    <Route path={Routes.Room} component={RoomPage} />
                    <Route
                        path={Routes.VotingHistory}
                        component={VotingHistoryPage}
                    />
                </Router>
            </DependencyInjectionContextProvider>
        </TranslationContextProvider>
    );
};

export default App;
