import Room from '@model/api/response/Room';
import { makePersisted } from '@solid-primitives/storage';
import { createStore, reconcile, SetStoreFunction } from 'solid-js/store';

class RoomStorage {
    private rooms: Room[];
    private setRooms: SetStoreFunction<Room[]>;

    constructor() {
        const [rooms, setRooms] = makePersisted(createStore<Room[]>([]), {
            storage: localStorage,
        });
        this.setRooms = setRooms;
        this.rooms = rooms;
    }

    public getRoom(roomId: string): Room | null {
        const room = this.rooms.find((x) => x.roomId === roomId);
        return room ?? null;
    }

    public createOrUpdateRoom(room: Room): void {
        const index = this.getIndexOfRoom(room.roomId);
        if (index < 0 || index >= this.rooms.length) {
            this.createRoom(room);
            return;
        }
        this.updateRoomAt(room, index);
    }

    private createRoom(room: Room): void {
        this.setRooms(this.rooms.length, room);
    }

    private updateRoomAt(room: Room, index: number) {
        this.setRooms(index, reconcile(room));
    }

    private getIndexOfRoom(roomId: string): number {
        return this.rooms.map((x) => x.roomId).indexOf(roomId);
    }
}

export default RoomStorage;
