import { useDi } from '@di/DependencyInjectionProvider';
import JoinPage from '@pages/join';
import LobbyPage from '@pages/lobby';
import VotingPage from '@pages/voting';
import VotingEndPage from '@pages/voting/end/votingEndPage';
import VotingResultPage from '@pages/voting/result';
import { useParams } from '@solidjs/router';
import { Component, Switch, Match } from 'solid-js';

const RoomPage: Component = () => {
    const di = useDi();
    const params = useParams();
    const roomId = params.roomId;
    di.sessionService.setRoomId(roomId);

    return (
        <Switch fallback={<JoinPage />}>
            <Match when={di.sessionService.room.state === 'Lobby'}>
                <LobbyPage />
            </Match>
            <Match when={di.sessionService.room.state === 'Voting'}>
                <VotingPage />
            </Match>
            <Match when={di.sessionService.room.state === 'Result'}>
                <VotingResultPage />
            </Match>
            <Match when={di.sessionService.room.state === 'End'}>
                <VotingEndPage />
            </Match>
        </Switch>
    );
};

export default RoomPage;
