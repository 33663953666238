import VotingEndSection from '@organisms/VotingEndSection';
import { useNavigate } from '@solidjs/router';
import { createSignal, type Component } from 'solid-js';
import { useDi } from '@di/DependencyInjectionProvider';
import AsyncOperation from '@root/AsyncOperation';
import LoadingOverlay from '@atoms/LoadingOverlay';

const VotingEndPage: Component = () => {
    const [userName, setUserName] = createSignal('');
    const navigation = useNavigate();
    const di = useDi();

    const createRoomOperation = new AsyncOperation<
        { username: string },
        void
    >();
    di.sessionService.endSession();
    return (
        <LoadingOverlay isLoading={createRoomOperation.resource.loading}>
            <VotingEndSection
                userName={userName}
                setUserName={setUserName}
                onCreateSession={() => {
                    createRoomOperation.start(
                        { username: userName() },
                        async (args) => {
                            await di.sessionService.startSession(args.username);
                            navigation(
                                di.routeBuilderService.buildRoomRoute(
                                    di.sessionService.roomId ?? '',
                                ),
                            );
                        },
                    );
                }}
                onJoinExistingRoom={() => {
                    // TODO: There is nothing to do right now for join without room number
                }}
                sessionNumber={di.sessionService.room.roomNumber?.toString()}
            />
        </LoadingOverlay>
    );
};

export default VotingEndPage;
