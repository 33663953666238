import { ParentComponent, createContext, useContext } from 'solid-js';
import AuthService from '@services/AuthService';
import AvatarProvider from '@services/AvatarProvider';
import PlayersManager from '@services/PlayersManager';
import SessionService from '@services/SessionService';
import Api from '@root/api/api';
import SignalRApi from '@root/api/signalRApi';
import RouteBuilderService from '@services/RouteBuilderService';
import RoomStorage from '@services/RoomStorage';

interface DependencyInjectionState {
    api: Api;
    signalRApi: SignalRApi;
    authService: AuthService;
    avatarProvider: AvatarProvider;
    playersManager: PlayersManager;
    sessionService: SessionService;
    routeBuilderService: RouteBuilderService;
    roomStorage: RoomStorage;
}

const DependencyInjectionContext = createContext<DependencyInjectionState>(
    {} as DependencyInjectionState,
);

export const useDi = () => useContext(DependencyInjectionContext);

export const DependencyInjectionContextProvider: ParentComponent = (props) => {
    const api = new Api();
    const signalRApi = new SignalRApi();
    const routeBuilderService = new RouteBuilderService();
    const authService = new AuthService();
    const avatarProvider = new AvatarProvider();
    const playersManager = new PlayersManager(avatarProvider);
    const roomStorage = new RoomStorage();
    const sessionService = new SessionService(
        playersManager,
        signalRApi,
        routeBuilderService,
        api,
        roomStorage,
    );

    const state: DependencyInjectionState = {
        api,
        signalRApi,
        authService,
        avatarProvider,
        playersManager,
        sessionService,
        routeBuilderService,
        roomStorage,
    };

    return (
        <DependencyInjectionContext.Provider value={state}>
            {props.children}
        </DependencyInjectionContext.Provider>
    );
};
