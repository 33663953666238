import LoadingOverlay from '@atoms/LoadingOverlay';
import { useDi } from '@di/DependencyInjectionProvider';
import JoinSection from '@organisms/JoinSection';
import AsyncOperation from '@root/AsyncOperation';
import { useNavigate } from '@solidjs/router';
import { createSignal, type Component } from 'solid-js';

const JoinPage: Component = () => {
    const [userName, setUserName] = createSignal('');
    const di = useDi();
    const navigation = useNavigate();
    const joinRoomOperation = new AsyncOperation<{ username: string }, void>();

    return (
        <LoadingOverlay isLoading={joinRoomOperation.resource.loading}>
            <JoinSection
                userName={userName}
                setUserName={setUserName}
                onJoinExistingRoom={() => {
                    joinRoomOperation.start(
                        { username: userName() },
                        async (args) => {
                            await di.sessionService.joinSession(
                                args.username,
                                di.sessionService.roomId!,
                            );
                            navigation(
                                di.routeBuilderService.buildRoomRoute(
                                    di.sessionService.roomId!,
                                ),
                            );
                        },
                    );
                }}
            />
        </LoadingOverlay>
    );
};

export default JoinPage;
