import { useNavigate } from '@solidjs/router';
import { useDi } from '@di/DependencyInjectionProvider';
import LoginSection from '@organisms/LoginSection';
import { createSignal, type Component } from 'solid-js';
import Routes from '@root/Routes';
import AsyncOperation from '@root/AsyncOperation';
import LoadingOverlay from '@atoms/LoadingOverlay';

const LoginPage: Component = () => {
    const navigate = useNavigate();
    const [email, setEmail] = createSignal('');
    const [password, setPassword] = createSignal('');
    const di = useDi();
    const authService = di.authService;

    const loginOperation = new AsyncOperation<
        { email: string; password: string },
        void
    >();

    return (
        <LoadingOverlay isLoading={loginOperation.resource.loading}>
            <LoginSection
                email={email}
                setEmail={setEmail}
                password={password}
                setPassword={setPassword}
                onLoginClicked={() => {
                    loginOperation.start(
                        { email: email(), password: password() },
                        async (x) => {
                            const success = await authService.Login(
                                x.email,
                                x.password,
                            );
                            if (success) {
                                navigate('/');
                            }
                        },
                    );
                }}
                onRegisterClicked={() => {
                    navigate(Routes.Register);
                }}
                onForgotPasswordClicked={() => {}}
                onMicrosoftLoginClicked={() => {}}
                onGithubLoginClicked={() => {}}
                onGoogleLoginClicked={() => {}}
                isLoading={loginOperation.resource.loading}
            />
        </LoadingOverlay>
    );
};

export default LoginPage;
