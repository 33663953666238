import VotingSection from '@organisms/VotingSection';
import { type Component } from 'solid-js';
import { useDi } from '@di/DependencyInjectionProvider';
import { PlayerVote, playerVoteToServerVote } from '@model/PlayerVote';
import { useNavigate } from '@solidjs/router';
import AsyncOperation from '@root/AsyncOperation';
import LoadingOverlay from '@atoms/LoadingOverlay';

const VotingPage: Component = () => {
    const di = useDi();
    const playersManager = di.playersManager;
    const navigate = useNavigate();

    const asyncOperation = new AsyncOperation<object, void>();

    return (
        <LoadingOverlay isLoading={asyncOperation.resource.loading}>
            <VotingSection
                isModerator={playersManager.selfPlayer()?.IsModerator ?? false}
                pokerAddress={di.sessionService.getJoinSessionLink()}
                sessionNumber={di.sessionService.room.roomNumber ?? 0}
                players={playersManager.players}
                ticketName={di.sessionService.room.ticketDescription}
                selectedScore={() => playersManager.selfPlayer()?.Vote ?? null}
                setSelectedScore={(newVote: PlayerVote) => {
                    playersManager.modifySelf((player) => {
                        player.Vote = newVote;
                        player.Status = newVote !== null ? 'Ready' : 'Waiting';
                    });
                    asyncOperation.start({}, async () => {
                        const roomId = di.sessionService.room.roomId ?? '';
                        const playerId = di.sessionService.room.playerId ?? '';
                        await di.api.vote(
                            roomId,
                            playerId,
                            playerVoteToServerVote(newVote),
                        );
                    });
                }}
                onRevealVotes={() => {
                    asyncOperation.start({}, async () => {
                        const roomId = di.sessionService.room.roomId ?? '';
                        const playerId = di.sessionService.room.playerId ?? '';
                        await di.api.openHand(roomId, playerId);
                    });
                }}
                onEndSession={(sessionName) => {
                    asyncOperation.start({}, async (_) => {
                        const roomId = di.sessionService.room.roomId ?? '';
                        const playerId = di.sessionService.room.playerId ?? '';
                        await di.api.endRoom(roomId, playerId, sessionName);
                    });
                }}
                onShowVotingHistory={() => {
                    navigate(
                        di.routeBuilderService.buildHistoryLink(
                            di.sessionService.room.roomId,
                        ),
                    );
                }}
            />
        </LoadingOverlay>
    );
};

export default VotingPage;
