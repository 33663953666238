import * as Sentry from '@sentry/solid';
import { render } from 'solid-js/web';

import './index.scss';
import App from './App';

if (!import.meta.env.DEV) {
    Sentry.init({
        dsn: 'https://3a9f102360fd441c50e3cfd56cffbb84@o4508018158862336.ingest.de.sentry.io/4508018225053777',
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration(),
        ],
        tracesSampleRate: 1.0,
        tracePropagationTargets: [
            'localhost',
            /^https:\/\/www\.pokercity\.tech/,
        ],
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });
}

const root = document.getElementById('root');

if (import.meta.env.DEV && !(root instanceof HTMLElement)) {
    throw new Error(
        'Root element not found. Did you forget to add it to your index.html? Or maybe the id attribute got misspelled?',
    );
}

render(() => <App />, root!);
