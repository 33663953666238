import Routes from '@root/Routes';

class RouteBuilderService {
    public buildRoomRoute(roomId: string) {
        return this.replaceArg(Routes.Room, 'roomId', roomId);
    }

    public buildHistoryLink(roomId: string) {
        return this.replaceArg(Routes.VotingHistory, 'roomId', roomId);
    }

    private replaceArg(route: string, name: string, value: string) {
        return route.replace(':' + name, value);
    }
}

export default RouteBuilderService;
